import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import images from './assets/**.png';

gsap.utils.toArray(".scroll-appear").forEach((element) => {
    gsap.to(element, {
        scrollTrigger: {
            trigger: element,
            start: "center 70%",
            once: true,
            end: "center 30%",
            //markers: true,
            onEnter: () => {
                gsap.fromTo(element, {opacity: 0, scale: 0.8}, {
                    scale: 1,
                    duration: 0.5,
                    opacity: 1
                });
            },
            onLeave: () => {
            },
            onEnterBack: () => {
            },
            onLeaveBack: () => {
            }
        }
    });
});



gsap.to(".radial-gradient", {
    yPercent: -100,
    ease: "none",
    scrollTrigger: {
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true
    },
});

gsap.to(".diamonds", {
    yPercent: -200,
    ease: "none",
    scrollTrigger: {
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true
    },
});

let el = document.querySelector('.scroll-into-view');
el.addEventListener('click', function (e) {
    e.preventDefault();
    const element = document.querySelector(".section-content-back");

    element.scrollIntoView({ behavior: "smooth" });
})

const STEPS = [
    {
        city: images['city-1'],
        meme: images['meme-1'],
        text: "Je préfère le vrai<br/>Bourg-en-Paume"
    },
    {
        city: images['city-2'],
        meme: images['meme-2'],
        text: "J'ai dit le vrai<br/>Bourg-en-Paume..."
    },
    {
        city: images['city-3'],
        meme: images['meme-3'],
        text: "Ca c'est la... <br/>Perfection !"
    }
];

let currentStep = 0;

let memeBtn = document.querySelector('.meme-container .btn');
memeBtn.addEventListener('click', function (e) {
    e.preventDefault();

    if(STEPS[currentStep + 1]) {
        currentStep ++ ;

        let cityImg = document.querySelector('.city-img img');
        let memeImg = document.querySelector('.meme-img img');

        cityImg.setAttribute('src', STEPS[currentStep].city);
        memeImg.setAttribute('src', STEPS[currentStep].meme);
        memeBtn.querySelector('span').innerHTML = STEPS[currentStep].text;
    }
})