const _temp0 = require("./assets/city-1.png");
const _temp1 = require("./assets/city-2.png");
const _temp2 = require("./assets/city-3.png");
const _temp3 = require("./assets/fakemon.png");
const _temp4 = require("./assets/favicon.png");
const _temp5 = require("./assets/logo.png");
const _temp6 = require("./assets/map.png");
const _temp7 = require("./assets/meme-1.png");
const _temp8 = require("./assets/meme-2.png");
const _temp9 = require("./assets/meme-3.png");
const _temp10 = require("./assets/pattern.png");
const _temp11 = require("./assets/puzzle.png");
const _temp12 = require("./assets/rarity.png");
module.exports = {
  "city-1": _temp0,
  "city-2": _temp1,
  "city-3": _temp2,
  "fakemon": _temp3,
  "favicon": _temp4,
  "logo": _temp5,
  "map": _temp6,
  "meme-1": _temp7,
  "meme-2": _temp8,
  "meme-3": _temp9,
  "pattern": _temp10,
  "puzzle": _temp11,
  "rarity": _temp12
}